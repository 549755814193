* {
	box-sizing: border-box;
	padding: 0;
	margin: 0;
	user-select: none;
}

html {
	overflow: hidden;
	width: 100%;
}

body {
	height: 100%;
	position: fixed;
	/* prevent overscroll bounce*/
	overflow-y: scroll;
	-webkit-overflow-scrolling: touch;
}
